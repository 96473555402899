import Vue from 'vue'
import Vuex from 'vuex'
import * as Api from './api'

Vue.use(Vuex)

const state = {
    events: [],
    event: {},
    races: [],
    race: {},
    competitors: [],
    competitor: {},
    auth: '',
    lastReady: -1,
    raspStatus: '',
    arrvivals: [
        {
            number: 22,
            name: 'Grande capo',
            surname: 'Bob aggiustatutto',
            percConfirm: 80,
        }, {
            number: 21,
            name: 'Grande fratello',
            surname: 'Bob Dylan',
            percConfirm: 10,
        }, {
            number: 43,
            name: 'Occhio',
            surname: 'Pé',
            percConfirm: 6,
        }, {
            number: 99,
            name: 'Cristian',
            surname: 'De sica',
            percConfirm: 4,
        },
    ],
    error: false,
}

const actions = {

   // COMPETITORS ACTIONS //

   loadCompetitors(context) {
      return Api.loadCompetitors()
         .then((response) => {
            context.commit('setCompetitors', response.data)
         })
         .catch((error) => {
            console.log("Error: can't load competitors")
            console.log(error)
         })
   },

   deleteCompetitor(context, competitor) {
      return Api.deleteCompetitor(competitor.id)
         .then((response) => {
            context.commit('deleteCompetitor', response.data)
         })
         .catch((error) => {
            console.log(error)
         })
   },

   // RACES ACTIONS //

   loadRace(context, {race_id}) {
      return Api.loadRace(race_id)
         .then((response) => {
            context.commit('setRace', response.data)
         })
         .catch((error) => {
            console.log(`Error: can't load race #${race_id}`)
            console.log(error)
         })
   },

   saveRace(context, race) {
      if (race.id > 0) {
         return Api.updateRace(race)
            .then((response) => {
               context.commit('updateRace', response.data)
            })
            .catch((error) => {
               console.log(error)
            })
      } else {
         return Api.createRace(race)
            .then((response) => {
               context.commit('createRace', response.data)
            })
            .catch((error) => {
               console.log(error)
            })
      }
   },

   deleteRace(context, race) {
      return Api.deleteRace(race.id)
         .then((response) => {
            context.commit('deleteRace', response.data)
         })
         .catch((error) => {
            console.log(error)
         })
   },

   // EVENTS ACTIONS //

   loadEvents(context) {
      return Api.loadEvents()
         .then((response) => {
            context.commit('setEvents', response.data)
         })
         .catch((error) => {
            console.log(`Error: can't load events`)
            console.log(error)
         })
   },

   loadEvent(context, {event_id}) {
      return Api.loadEvent(event_id)
         .then((response) => {
            context.commit('setEvent', response.data)
         })
         .catch((error) => {
            console.log(`Error: can't load the event #${event_id} infos`)
            console.log(error)
         })
   },

   deleteEvent(context, event) {
      console.log(event)
      return Api.deleteEvent(event.id)
         .then((response) => {
            context.commit('deleteEvent', response.data)
         })
         .catch((error) => {
            console.log(error)
         })
   },

    authenticate(context, {email, password}) {
        return Api.authenticate({email, password})
            .then((response) => {
                context.commit('setAuthentication', response.data)
            })
            .catch((error) => {
                console.log(`Error: can't authenticate ${email}`)
                console.log(error)
            })
    },

    saveEvent(context, event) {
        if (event.id > 0) {
            return Api.updateEvent(event)
                .then((response) => {
                    context.commit('updateEvent', response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            return Api.createEvent(event)
                .then((response) => {
                    context.commit('createEvent', response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },

    uploadCompetitorImg(context, img) {
        return Api.uploadCompetitorImg(img.id, img.image)
            .then((response) => {
                //console.log(response.data)
                context.commit('uploadCompetitorImg', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },

    saveCompetitor(context, competitor) {
        // console.log(competitor)
        if (competitor.id > 0) {
            return Api.updateCompetitor(competitor)
                .then((response) => {
                    //console.log(response.data.competitor.id)
                    context.commit('updateCompetitor', response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            return Api.createCompetitor(competitor)
                .then((response) => {
                    context.commit('createCompetitor', response.data)
                    //console.log("CREATED COMPETITOR ID:", response.data.competitor.id)
                    if (competitor.image) {
                        context.dispatch('uploadCompetitorImg', {
                            id: response.data.competitor.id,
                            image: competitor.image
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    },
    logOut(context) {
      return Api.logout()
        .then((response) => {
          context.commit('setLogOut', response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    logged(context) {
        // console.log('Store:logged')
        return Api.logged()
            .then((response) => {
                context.commit('logged', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    // ready(context, value) {
    //   return Api.ready(comeptitor_id)
    //         .then((response) => {
    //             context.commit('setLastReady', response.data)
    //         })
    //         .catch((error) => {
    //             console.log(`Error: can't prepare competitor #${competitor_id}`)
    //             console.log(error)
    //         })
    // },
    raspStatus(context, {username, password}) {
        return Api.raspStatus(username, password)
            .then((response) => {
                context.commit('setRaspStatus', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    setError(context, error) {
        state.error = error
    },
}

const mutations = {
    setCompetitors(state, payload) {
        state.competitors = payload.competitors
    },
    setRace(state, payload) {
        state.competitors = payload.races.competitors
        delete payload.races.competitors
        state.race = payload.races
    },
    setEvents(state, payload) {
        state.events = payload.events
        state.events.sort((a, b) => {
            return (a.date > b.date) - (b.date > a.date)
        })
    },
    setEvent(state, payload) {
        state.races = payload.event.races
        delete payload.event.races
        state.event = payload.event
    },
    setAuthentication(state, payload) {
        state.auth = payload.user
        if (!state.auth)
            state.error = true
    },
    logged(state, payload) {
        state.auth = payload.user
    },
    setLogOut(state, payload) {
        state.auth = payload.user
    },
    updateEvent(state, payload) {
        const index = state.events.findIndex(x => x.id == payload.event.id)
        // exchange with the newer
        state.events.splice(index, 1, payload.event)
    },
    createEvent(state, payload) {
        state.events.unshift(payload.event)
    },
    deleteEvent(state, payload) {
        const index = state.events.findIndex(x => x.id == payload.event.id)
        // exchange with the newer
        state.events.splice(index, 1)
    },
    updateRace(state, payload) {
        const index = state.races.findIndex(x => x.id == payload.race.id)
        // exchange with the newer
        state.races.splice(index, 1, payload.race)
    },
    createRace(state, payload) {
        state.races.unshift(payload.race)
    },
    deleteRace(state, payload) {
        const index = state.races.findIndex(x => x.id == payload.race.id)
        // exchange with the newer
        state.races.splice(index, 1)
    },
    updateCompetitor(state, payload) {
        const index = state.competitors.findIndex(x => x.id == payload.competitor.id)
        // exchange with the newer
        state.competitors.splice(index, 1, payload.competitor)
    },
    uploadCompetitorImg(state, payload) {
        const index = state.competitors.findIndex(x => x.id == payload.competitor.id)
        payload.competitor.avatar_pic += "?rand=" + (Math.random()*10000).toFixed()  // BAD TRICK
        state.competitors.splice(index, 1, payload.competitor)
        console.log("Competitor: image uploaded", payload.competitor)
    },
    createCompetitor(state, payload) {
        state.competitors.unshift(payload.competitor)
    },
    deleteCompetitor(state, payload) {
        const index = state.competitors.findIndex(x => x.id == payload.competitor.id)
        //console.log(payload.competitor, index)
        state.competitors.splice(index, 1)
    },
    // setLastReady(state, payload) {
    //     state.lastReady = payload.lastReady
    // },
    setRaspStatus(state, payload) {
        state.raspStatus = payload
    }
}

const store = new Vuex.Store({
   state,
   actions,
   mutations
})

export default store;
