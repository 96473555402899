<template>
  <v-card class="rounded-xl" color="transparent">
    <v-card class="rounded-xl pa-5" light>
      <v-card-title>
        Competitors
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            hide-details
            label="Search"
            single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="competitors"
          :search="search"
          :sort-by="['ranking']"
      >
        <template v-slot:item.image="{ item }">
            <v-avatar class="grey text-center" size="50" style="cursor: pointer;" @click="pickImg">
              <img :id="'avatar_'+ item.id" class="imgProfile" :src="`${publicPath}images/competitors/${item.avatar_pic}`" @error="useDefault">
            </v-avatar>
        </template>
        <template v-if="auth" v-slot:item.actions="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-card-actions class="pa-3">
      <v-dialog v-if="auth" v-model="dialog" persistent width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-model="dialog" v-bind="attrs" v-on="on" absolute color="white" dark elevation="4" fab large
                 persistent right
                 top>
            <v-icon class="black--text" style="font-size: 2rem">mdi-account-plus-outline</v-icon>
          </v-btn>
        </template>
        <v-card class="rounded-xl pb-3">
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container>
                <v-img></v-img>
                <v-avatar class="grey text-center" size="90" style="cursor: pointer;" @click="pickImg">
                  <v-img :src="imageUrl"></v-img>
                </v-avatar>
                <label class="ml-2">Pick an image</label>
                <v-btn class="ml-2" color="red" elevation="0" fab outlined x-small @click="clearUrl">
                  <v-icon class="red--text">mdi-cancel</v-icon>
                </v-btn>

                <!--                <v-btn color="#111111" large fab dark @click="pickImg"><v-icon>mdi-image-outline</v-icon></v-btn>-->
                <!--                <label style="font-size: 1rem">Upload Avatar</label>-->
                <input ref="fileInput" accept=".jpg" style="display: none" type="file" @change="onFilePicked">
                <v-text-field v-model="editedItem.surname" :rules="nameRule" color="black" label="Surname"
                              required></v-text-field>
                <v-text-field v-model="editedItem.name" :rules="nameRule" color="black" label="Name"
                              required></v-text-field>
                <v-text-field v-model="editedItem.birthdate" :rules="dateRule" color="black" label="Birth date" required
                              type="date"></v-text-field>
              </v-container>
              <v-card-actions>
                <v-btn color="black darken-1" text @click="close()">
                  Close
                </v-btn>
                <v-btn color="black darken-1" text @click="save()">
                  {{ dialogButton }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-if="auth" v-model="dialogDelete" max-width="500px" class="rounded-xl">
        <v-card class="rounded-xl pb-3 pr-10">
          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="dark darken-1" text @click="closeDelete()">Cancel</v-btn>
            <v-btn color="dark darken-1" text @click="deleteItemConfirm()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
import defaultCompetitorImage from '@/assets/defaultCompetitorImage.jpg'

export default {

  name: "Competitors",

  data: () => ({
    publicPath: process.env.VUE_APP_BASE_URL,
    image: null,
    imageUrl: null,
    race_id: null,
    search: '',
    nameRule: [
      v => v.length >= 1 || 'Please insert something'
    ],
    dateRule: [
      v => v.length >= 1 || 'Please insert the date please'
    ],
    headers: [
      {text: '', value: 'image', sortable: false, width: '20px', align: 'center'},
      {text: 'Surname', value: 'surname'},
      {text: 'Name', value: 'name'},
      {text: 'BirthDate', value: 'birthdate'},
      {text: '', value: 'actions', sortable: false},
    ],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    defaultItem: {
      id: '',
      surname: '',
      name: '',
      birthdate: '',
    },
    editedItem: {
      id: '',
      surname: '',
      name: '',
      birthdate: '',
    },
  }),

  beforeMount() {
    this.race_id = this.$route.params.id
    this.$store.dispatch('loadCompetitors')
  },

  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? 'Create competitor' : 'Edit competitor'
    },
    dialogButton() {
      return this.editedIndex === -1 ? 'Create' : 'Edit'
    },
    ...mapState({
      race: state => state.race,
      competitors: state => state.competitors,
      auth: state => state.auth,
    }),
  },

  methods: {
    useDefault(e){
      e.target.src = defaultCompetitorImage;
    },
    clearUrl() {
      this.imageUrl = ""
    },
    pickImg() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files
      let filename = files[0].name;
      if (filename.lastIndexOf('.') <= 0) {
        return alert('Please insert a valid photo')
      }
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.image = files[0]
    },
    editItem(item) {
      this.editedIndex = this.competitors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.competitors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.dispatch('deleteCompetitor', this.editedItem)
      //this.competitors.splice(this.editedIndex, 1) --> già fatta nello store
      this.closeDelete()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('saveCompetitor', {...this.editedItem, image: this.image})
        // if (this.image) {
        //   this.$store.dispatch('uploadCompetitorImg', {id: this.editedItem.id, image: this.image})
        // }
        this.close()
      }
    },
  }
}
</script>

<style scoped>

  .imgProfile{
    overflow: hidden;
    height: auto !important;
    width: auto !important;
    min-width: 80px !important;
    min-height: 80px !important;
  }

</style>
