<template>
  <v-card class="rounded-xl pa-4">
    <v-card-title class="titleRes main justify-center">
      <b class="text-center">Corse dell'evento {{ event_id }}</b>
    </v-card-title>
    <v-card class="row justify-center" elevation="0">
      <v-card v-for="(race,index) in races" :key="race.headers" class="rounded-xl ml-6 mt-10 pa-lg-2  col-sm-5 mb-12"
              dark>
        <v-list>
          <v-card-title class="justify-center pa-3"><b> Corsa {{ index + 1 }} </b></v-card-title>
          <v-list-item-content>
            <v-list-item>Ora di inizio: {{ race.start_time }}</v-list-item>
            <v-list-item>Descrizione: {{ race.description }}</v-list-item>
          </v-list-item-content>
          <v-btn color="white" fab dark large absolute bottom center @click="editItem(race)" v-if="auth">
            <v-icon class="black--text" style="font-size: 2rem">mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn color="white ml-16" fab dark large absolute bottom center @click="deleteItem(race)" v-if="auth">
            <v-icon class="black--text" style="font-size: 2rem">mdi-delete</v-icon>
          </v-btn>
          <v-btn color="white" fab dark large absolute top right v-if="auth" class="mr-16 mr-xs-20 mt-2" elevation="20"
                 :to="'/start/'+race.id">
            <v-icon class="black--text" style="font-size: 2rem">mdi-timer-outline</v-icon>
          </v-btn>
          <v-btn color="white" fab dark large absolute top right v-if="auth" class="mr-n1 mt-2" elevation="20"
                 :to="'/arrival/'+race.id">
            <v-icon class="black--text" style="font-size: 2rem">mdi-flag-checkered</v-icon>
          </v-btn>
          <v-btn color="white" fab dark large absolute bottom right :to="'/race/'+race.id">
            <v-icon class="black--text" style="font-size: 2rem">mdi-arrow-right</v-icon>
          </v-btn>
        </v-list>
      </v-card>
    </v-card>
    <v-card-actions>
      <v-dialog v-model="dialog" width="500" persistent v-if="auth">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" fab dark large top elevation="20" absolute right v-model="dialog" persistent
                 v-bind="attrs" v-on="on">
            <v-icon class="black--text" style="font-size: 2rem">mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card class="rounded-xl pb-3">
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field v-model="editedItem.start_time" label="Starts hour" type="time"></v-text-field>
              <v-text-field v-model="editedItem.description" label="Description"></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="black darken-1" text @click="close()">
              Close
            </v-btn>
            <v-btn color="black darken-1" text @click="save()">
              {{ dialogButton }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px" v-if="auth">
        <v-card class="rounded-xl pb-3">
          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="dark darken-1" text @click="closeDelete()">Cancel</v-btn>
            <v-btn color="dark darken-1" text @click="deleteItemConfirm()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    <v-btn color="white" fab dark large absolute top left @click="back()">
      <v-icon class="black--text" style="font-size: 2rem">mdi-arrow-left</v-icon>
    </v-btn>
  </v-card>
</template>

<script>


import {mapState} from 'vuex'

export default {
  name: "races",
  components: {},
  data: () => ({
    event_id: null,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    defaultItem: {
      id: -1,
      event_id: -1,
      start_time: '',
      description: '',
    },
    editedItem: {
      id: -1,
      event_id: -1,
      start_time: '',
      description: '',
    },
  }),
  beforeMount() {
    this.defaultItem.event_id = this.editedItem.event_id = this.event_id = this.$route.params.id
    this.$store.dispatch('loadEvent', {event_id: this.event_id})
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? 'Create event' : 'Edit event'
    },
    dialogButton() {
      return this.editedIndex === -1 ? 'Create' : 'Edit'
    },
    ...mapState({
      event: state => state.event,
      races: state => state.races,
      auth: state => state.auth,
    }),
  },
  // watch: {
  //   dialog(visible) {
  //     if(visible) {
  //       this.editedItem = {...this.defaultItem}
  //     }
  //   },
  // },
  methods: {
    editItem(item) {
      this.editedIndex = this.races.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    save() {
      this.$store.dispatch('saveRace', this.editedItem)
      this.close()
    },
    back() {
      this.$router.go(-1)
    },
    deleteItem(item) {
      // console.log(item)
      this.editedIndex = this.races.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.dispatch('deleteRace', this.editedItem)
      this.races.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
  },
}
</script>

<style scoped>

</style>