import axios from "axios"

// axios.defaults.withCredentials = true

let API_URL = process.env.VUE_APP_API_URL


export function hello(name) {
    return axios.get(`${API_URL}/hello/${name}/`)
}

// api to get all the info about a race and the competitors linked to it
export function loadRace(race_id) {
    return axios.get(`${API_URL}/races/${race_id}/`)
}

// api to get all the events with their basic infos
export function loadEvents() {
    return axios.get(`${API_URL}/events/`, {
        params: {
            depth: 1
        }
    })
}

// api to get all the info about an event and the race linked to it
export function loadEvent(event_id) {
    return axios.get(`${API_URL}/events/${event_id}/`, {
        params: {
            depth: 1
        }
    })
}

// api to authenticate someone
export function authenticate(credentials) {
    return axios.post(`${API_URL}/auth/login/`, credentials)
}

export function logged() {
    return axios.get(`${API_URL}/auth/me`)
}

export function logout() {
  return axios.get(`${API_URL}/auth/logout/`)
}

export function updateEvent(event) {
    return axios.put(`${API_URL}/events/${event.id}/`, event)
}

export function createEvent(event) {
    return axios.post(`${API_URL}/events/`, event)
}

export function deleteEvent(id) {
  return axios.delete(`${API_URL}/events/${id}/`)
}

export function updateRace(race) {
    return axios.put(`${API_URL}/races/${race.id}/`, race)
}

export function createRace(race) {
    return axios.post(`${API_URL}/races/`, race)
}

export function deleteRace(id) {
  return axios.delete(`${API_URL}/races/${id}/`)
}

// COMPETITORS API'S //

export function loadCompetitors() {
  return axios.get(`${API_URL}/competitors/`, {
    params: {
      depth: 0
    }})
}

export function updateCompetitor(competitor) {
    return axios.put(`${API_URL}/competitors/${competitor.id}/`, competitor)
}

export function createCompetitor(competitor) {
    return axios.post(`${API_URL}/competitors/`, competitor)
}

export function uploadCompetitorImg(competitor_id, img) {
    let formData = new FormData();
    formData.append('image', img);
    console.log(competitor_id)
    return axios.post(`${API_URL}/competitors/avatar/${competitor_id}/`,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
}

export function deleteCompetitor(id) {
  return axios.delete(`${API_URL}/competitors/${id}/`)
}

// RASPBERRY PI API'S //

export function raspStatus(username, password) {
    return axios.get(`https://chrono-cerebotani.loca.lt/api/status/`, {
        auth: {
            username: username,
            password: password,
        }
    })
}

/**

 C  POST   insert
 R  GET    select 1/*
 U  PUT    update
 D  DELETE delete

 GET /api/users/<id>/  --> return user id = id
 GET /api/users/       --> return all users
 POST /api/users/ + dati --> insert, return inserted user + id
 PUT /api/users/<id> + dati  --> update, return updated user
 DELETE /api/users/<id>  --> return

 {
    "ok": "true|false",
    "msg": "...",

    "user": {...},
    "users": [{...}, {...}, ...],
  }
 */

