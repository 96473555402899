<template>
  <v-app style="background: #111111">
    <v-system-bar color="#111111" dark height="80">
      <v-btn
          class=" ml-10"
          color="white"
          fab
          @click="drawer=!drawer"
      >
        <v-icon class="black--text">mdi-view-headline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <img class="mr-10" height="60px" src="./assets/Logo.png"/>
    </v-system-bar>
    <v-navigation-drawer v-model="drawer" app class="rounded-r-xl" elevation="25" temporary width="300px">
      <Navbar/>
    </v-navigation-drawer>
    <v-main class="pa-0 ma-10 mt-2">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// imports
import Navbar from './components/Navbar';
// import {mapState} from 'vuex'

export default {
  name: 'App',

  beforeMount() {
    this.$store.dispatch('logged')
  },

  data: () => ({
    drawer: false,
    route: '/login',
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
    state: '',
  }),

  // computed: {
  //   ...mapState({
  //     auth: state => state.auth
  //   })
  // },

  components: {
    Navbar,
  },
  methods: {
    // getCookie(cName) {
    //   const name = cName + "=";
    //   const cDecoded = decodeURIComponent(document.cookie); //to be careful
    //   const cArr = cDecoded.split('; ');
    //   let res;
    //   cArr.forEach(val => {
    //     if (val.indexOf(name) === 0) res = val.substring(name.length);
    //   })
    //   return res;
    // }
},
// beforeMount()
// {
//   // this.auth = this.getCookie('authCookie')
// }
}
</script>
