<template>
  <v-row align="center" justify="center">
    <!-- card containing the form -->
    <v-card class="rounded-xl rounded-xl pa-5 mt-10" width="80%">
      <!-- title -->
      <v-card-title class="justify-center mb-5">
        <b style="font-size:2rem">Contact Us</b>
      </v-card-title>
      <!-- name of the user -->
      <v-text-field
      class="rounded-xl"
      outlined
      shaped
      email
      label="Name"
      color="black">
      </v-text-field>
      <!-- email of the user -->
      <v-text-field
      class="rounded-xl"
      outlined
      shaped
      label="Email"
      color="black">
      </v-text-field>
      <!-- comment of the user -->
      <v-textarea
          class="rounded-xl"
          outlined
          shaped
          auto-grow
          color="black"
          label="Comment"
          rows="10"
      ></v-textarea>
      <v-card-actions class="justify-center ml-0">
        <v-btn width="8vh" height="8vh" fab color="black">
          <v-icon class="white--text" style="font-size:3rem">mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>

</template>

<script>
export default {
name: "About"
}
</script>

<style scoped>

</style>