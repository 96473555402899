<template>
  <v-card height="100%">
    <v-img
        class="align-end rounded"
        height="200px"
        src="https://www.canyon.com/dw/image/v2/BCML_PRD/on/demandware.static/-/Library-Sites-canyon-shared/default/dwb767bf6f/images/plp/Mountain/canyon-trail-bikes-world-1.jpg?sw=632&sh=650&sm=cut"
    >
      <v-avatar
          class="avatar white--text"
          size="85"
          color="#111111"
          v-if="auth"
      >{{ auth.first_name }}
      </v-avatar>
    </v-img>
    <v-list rounded>
      <v-list-item-group
          v-model="selection"
          color="black"
      >
        <v-list-item
            v-for="item in fitems"
            :key="item.text"
            :to="item.route"
            class="rounded"
            link router
        >
          <v-list-item-icon>
            <v-icon color="black">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title><b>{{ item.text }}</b></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-dialog v-model="dialogOut" persistent width="500" v-if="auth">
      <template v-slot:activator="{ on, attrs }" v-slot:default>
        <div class="pa-6 btn">
          <v-btn block class="rounded-xl logBtn" color="black" dark v-bind="attrs" v-on="on">
            Log-Out
          </v-btn>
        </div>
      </template>
      <v-card class="rounded-xl pb-3">
        <v-card-title class="justify-center">
          Sure to quit ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="rounded-xl" color="black" dark center @click="logout()">
            Yes
          </v-btn>
          <v-btn class="rounded-xl" color="black" dark center @click="close()">
            No
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent width="500" v-if="!auth">
      <template v-slot:activator="{ on, attrs }" v-slot:default>
        <div class="pa-6 btn">
          <v-btn block class="rounded-xl logBtn" color="black" dark v-bind="attrs" v-on="on">
            Log-In
          </v-btn>
        </div>
      </template>
      <v-card class="rounded-xl pb-3">
        <v-card-title class="text-center">
          Login
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field label="Email" color="black" v-model="email"></v-text-field>
            <v-text-field label="Password" type="password" color="black" v-model="password"></v-text-field>
          </v-form>
        </v-card-text>
        <v-alert dense outlined type="error" v-if="error" class="mx-5">
          Seems you messed up with your email or password!
        </v-alert>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="rounded-xl" color="black" dark center @click="close()">
            Cancel
          </v-btn>
          <v-btn class="rounded-xl" color="black" dark center @click="login()">
            Login
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      dialog: false,
      dialogOut: false,
      selection: 1,
      email: '',
      password: '',
      items: [
        {icon: 'mdi-home', text: 'Home', route: '/', auth: false},
        {icon: 'mdi-star-four-points', text: 'Upcoming events', route: '/events', auth: false},
        {icon: 'mdi-account-group ', text: 'Competitors', route: '/competitors', auth: true},
        // {icon: 'mdi-flag-checkered', text: 'Arrival Judge', route: '/arrival', auth: true},
        {icon: 'mdi-raspberry-pi', text: 'Raspberry Pi Status', route: '/status', auth: true},
        {icon: 'mdi-help-circle', text: 'Contact Us', route: '/about', auth: false},
      ],
    }
  },
  props: {
    //auth: [Object, Boolean],
    default: () => true
  },
  computed: {
    fitems: function () {
      return this.items.filter((i) => {
        return this.auth || !i.auth
      })
    },
    ...mapState({
      error: state => state.error,
      auth: state => state.auth,
    }),
  },
  methods: {
    close() {
      if (this.dialog) {
        this.dialog = false
        this.$store.dispatch('setError', false)
      }
      else
        this.dialogOut = false
    },
    logout() {
      this.$store.dispatch('logOut')
      this.dialogOut = false
    },
    login() {
      // console.log("login: ",this.username,this.password)
      this.$store.dispatch('authenticate', {email: this.email, password: this.password})
      if (this.error)
        this.dialog = false
    }
  }
}
</script>
