<template>
  <!--    <v-card-title class="justify-center">{{this.raspStatus.msg}}</v-card-title>-->
  <!--    <v-icon @click="writeStatus">mdi-pencil</v-icon>-->
  <!--    <v-card v-for="(gate) in this.raspStatus.status.gates" :key="gate.number" class="rounded-xl ma-5 col-sm-5 mb-12" dark>-->
  <!--        <v-list class="row&#45;&#45;dense justify-center" width="100%">-->
  <!--            <v-card-title class="justify-center row">Gate #{{gate.number}}</v-card-title>-->
  <!--            <v-card-text class="" v-if="gate.connected">-->
  <!--              Connected-->
  <!--            </v-card-text>-->
  <!--            <v-card-text class="" v-if="!gate.connected">-->
  <!--              Not connected-->
  <!--            </v-card-text>-->
  <!--            <v-card-text class="">-->
  <!--              Status: {{gate.status}}-->
  <!--            </v-card-text>-->
  <!--            <v-card-text class="">-->
  <!--              Type: {{gate.type}}-->
  <!--            </v-card-text>-->
  <!--        </v-list>-->
  <!--      </v-card>-->
  <v-card class="rounded-xl pa-5" light>
    <v-card-title>
      {{ this.raspStatus.msg }}
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="this.raspStatus.gates"
        :search="search"
        :sort-by="['number']"
        :no-data-text="this.raspStatus.error"
    >
      <!--      <template v-slot:item.actions="{ item }" v-if="auth">-->
      <!--        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>-->
      <!--        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>-->
      <!--      </template>-->
    </v-data-table>
    <!--    <v-btn @click="logMessage()"> CIAO</v-btn>-->
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Status",
  data: () => ({
    search: '',
    headers: [
      {text: 'Gate', value: 'number'},
      {text: 'Connected', value: 'connected'},
      {text: 'Status', value: 'status'},
      {text: 'Type', value: 'type'},
      // {text: '', value: 'actions', sortable: false},
    ],
  }),
  beforeMount() {
    this.$store.dispatch('raspStatus', {username: 'admin', password: '4dm1n'})
  },
  computed: {
    ...mapState({
      raspStatus: state => state.raspStatus,
      auth: state => state.auth
    })
  },
  methods: {
    logMessage() {
      console.log(this.raspStatus)
    },
  }
}
</script>

<style scoped>

</style>