<template>
  <v-card class="rounded-xl pa-5">
    <v-card-title class="titleRes main justify-center mb-5">
      <b>Events</b>
    </v-card-title>
    <v-card class=" rounded-xl row justify-center" elevation="0">
      <v-card v-for="(event, index) in events" :key="event.header" class="rounded-xl ml-6 mb-5 col-sm-5" dark>
        <v-list>
          <v-card-title class="titleRes Inner big justify-center pa-3"><b> Event number: {{ index + 1 }} </b>
          </v-card-title>
          <v-list-item-content>
            <!--            <v-list-item>Position: {{ event.location }}</v-list-item>-->
            <v-list-item>Date: {{ event.date }}</v-list-item>
            <v-list-item>Description: {{ event.description }}</v-list-item>
            <v-list-item>Races number: {{ event.races.length }}</v-list-item>
          </v-list-item-content>
          <v-card class="rounded-xl justify-center pa-3">
            <v-btn color="white" fab dark large absolute bottom center @click="editItem(event)" v-if="auth">
              <v-icon class="black--text" style="font-size: 2rem">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn color="white ml-16" fab dark large absolute bottom center @click="deleteItem(event)" v-if="auth">
              <v-icon class="black--text" style="font-size: 2rem">mdi-delete</v-icon>
            </v-btn>
            <v-btn color="white" fab dark large absolute bottom right :to="'/races/'+event.id">
              <v-icon class="black--text" style="font-size: 2rem">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card>
        </v-list>
      </v-card>
    </v-card>
    <v-card-actions>
      <v-dialog v-model="dialog" width="500" persistent v-if="auth">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" fab dark large top absolute elevation="20" right v-model="dialog" persistent
                 v-bind="attrs" v-on="on">
            <v-icon class="black--text" style="font-size: 2rem">mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card class="rounded-xl pb-3">
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <!--              <v-text-field color="black" v-model="editedItem.location" label="Position"></v-text-field>-->
              <v-text-field color="black" v-model="editedItem.date" label="Date" type="date"></v-text-field>
              <v-text-field color="black" v-model="editedItem.description" label="Description"></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="black darken-1" text @click="close()">
              Close
            </v-btn>
            <v-btn color="black darken-1" text @click="save()">
              {{ dialogButton }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px" v-if="auth">
        <v-card class="rounded-xl pb-3">
          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="dark darken-1" text @click="closeDelete()">Cancel</v-btn>
            <v-btn color="dark darken-1" text @click="deleteItemConfirm()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>


<script>
// imports
import {mapState} from 'vuex'

export default {
  name: "Events",
  components: {},
  data: () => ({
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    defaultItem: {
      id: -1,
      lat: 0,
      lon: 0,
      date: '',
      description: '',
    },
    editedItem: {
      id: -1,
      lat: 0,
      lon: 0,
      date: '',
      description: '',
    },
  }),
  beforeMount() {
    this.$store.dispatch('loadEvents')
    // this.$store.dispatch('authenticate', {username: 'pippo', password: 'pippo'})
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? 'Create event' : 'Edit event'
    },
    dialogButton() {
      return this.editedIndex === -1 ? 'Create' : 'Edit'
    },
    ...mapState({
      events: state => state.events,
      auth: state => state.auth,
    }),
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.events.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    save() {
      this.$store.dispatch('saveEvent', this.editedItem)
      this.close()
    },
    deleteItem(item) {
      console.log(item)
      this.editedIndex = this.events.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.dispatch('deleteEvent', this.editedItem)
      this.events.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
  },
}

</script>

<style scoped>

</style>