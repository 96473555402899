<template>
  <v-card class="rounded-xl" color="transparent">
    <v-card class="rounded-xl pa-5" light>
      <v-card-title>
        Leaderboard corsa {{ race_id }}
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            hide-details
            label="Search"
            single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="competitors"
          :search="search"
          :sort-by="['ranking']"
      >
        <template v-if="auth" v-slot:item.actions="{ item }">
          <v-icon class="mr-2" small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.ranking="{ item }">
          <v-chip :color="getColor(item.ranking)">
            {{ item.ranking }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <v-card-actions class="pa-3">
      <v-dialog v-model="dialog" width="600" v-if="auth">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" fab dark large top absolute right v-model="dialog" v-bind="attrs" v-on="on"
                 elevation="4">
            <v-icon class="black--text" style="font-size: 2rem">mdi-account-plus-outline</v-icon>
          </v-btn>
        </template>
        <v-card id="scrollCard" class="rounded-xl scroll pa-5" height="400px">
          <v-card-title class="white--text titleRes">Other Suggestions</v-card-title>
          <v-card v-for="item in items" :key="item" class="rounded-xl mb-8 pb-2 pl-2" color="white">
            <v-card-title class="titleRes Inner">
              Bib: #111111 <br>
              Name: Giovanni Giorgio
            </v-card-title>
            <v-btn absolute bottom class="mr-n1 mt-2" color="green" dark elevation="20" fab large right>
              <v-icon class="white--text" style="font-size: 2rem">mdi-check</v-icon>
            </v-btn>
          </v-card>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px" v-if="auth">
        <v-card class="rounded-xl pb-3">
          <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="dark darken-1" text @click="closeDelete()">Cancel</v-btn>
            <v-btn color="dark darken-1" text @click="dialogDelete = false">OK</v-btn>
            <v-spacer></v-spacer>

          </v-card-actions>

          <v-card-text class="pt-4">
            Insert a competitor into the race
          </v-card-text>

          <v-divider></v-divider>

          <v-virtual-scroll :item-height="50"  height="300"
          >
            <template v-slot:default="{ item }">
              <v-list-item>
                <v-list-item-avatar>
                  <v-avatar
                      :color="item.color"
                      class="white--text pr-4"
                      size="56"
                  >
<!--                    {{ item.initials }}-->
                  </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
<!--                  <v-list-item-title>{{ item.fullName }}</v-list-item-title>-->
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-dialog>
    </v-card-actions>
    <v-btn color="white" fab dark large absolute top left @click="back()">
      <v-icon class="black--text" style="font-size: 2rem">mdi-arrow-left</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "Race",
  data: () => ({
    race_id: null,
    search: '',
    colors: ['#2196F3', '#90CAF9', '#64B5F6', '#42A5F5', '#1E88E5', '#1976D2', '#1565C0', '#0D47A1', '#82B1FF', '#448AFF', '#2979FF', '#2962FF'],
    // names: ['Oliver', 'Jake', 'Noah', 'James', 'Jack', 'Connor', 'Liam', 'John', 'Harry', 'Callum', 'Mason', 'Robert', 'Jacob', 'Jacob', 'Jacob', 'Michael', 'Charlie', 'Kyle', 'William', 'William', 'Thomas', 'Joe', 'Ethan', 'David', 'George', 'Reece', 'Michael', 'Richard', 'Oscar', 'Rhys', 'Alexander', 'Joseph', 'James', 'Charlie', 'James', 'Charles', 'William', 'Damian', 'Daniel', 'Thomas', 'Amelia', 'Margaret', 'Emma', 'Mary', 'Olivia', 'Samantha', 'Olivia', 'Patricia', 'Isla', 'Bethany'],
    // surnames: ['Smith', 'Anderson', 'Clark', 'Wright', 'Mitchell', 'Johnson', 'Thomas', 'Rodriguez', 'Lopez', 'Perez', 'Williams', 'Jackson', 'Lewis', 'Hill', 'Roberts', 'Jones', 'White', 'Lee', 'Scott', 'Turner', 'Brown', 'Harris', 'Walker', 'Green', 'Phillips', 'Davis', 'Martin', 'Hall', 'Adams', 'Campbell', 'Miller', 'Thompson', 'Allen', 'Baker', 'Parker', 'Wilson', 'Garcia', 'Young', 'Gonzalez', 'Evans', 'Moore', 'Martinez', 'Hernandez', 'Nelson', 'Edwards', 'Taylor', 'Robinson', 'King', 'Carter', 'Collins'],

    headers: [
      {text: 'Ranking', value: 'ranking'},
      {text: 'Bib No.', value: 'number'},
      {text: 'Surname', value: 'surname'},
      {text: 'Name', value: 'name'},
      {text: 'BirthDate', value: 'birthdate'},
      {text: 'StartTime', value: 'start_time'},
      {text: 'FinishTime', value: 'finish_time'},
      {text: 'Time', value: 'time'},
      {text: '', value: 'actions', sortable: false},
    ],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    defaultItem: {
      number: '',
      surname: '',
      name: '',
      birthdate: '',
    },
    editedItem: {
      number: '',
      surname: '',
      name: '',
      birthdate: '',
    },
  }),
  beforeMount() {
    this.race_id = this.$route.params.id
    this.$store.dispatch('loadRace', {race_id: this.race_id})
    this.$store.dispatch('loadCompetitors')
  },
  computed: {
    items () {
      for (var i = 0; i < this.competitors.length; i++) {
        var name = [this.competitors.name]
        console.log(name)
      }
      return 0
    },
      // return{
      //       color: this.colors[genRandomIndex(colorsLength)],
      //       fullName: `${this.} ${this.competitors.surname}`,
      //       initials: `${this.competitors.name[0]} ${this.competitors.surname[0]}`,
      // }
    dialogTitle() {
      return this.editedIndex === -1 ? 'Create competitor' : 'Edit competitor'
    },
    dialogButton() {
      return this.editedIndex === -1 ? 'Create' : 'Edit'
    },
    ...mapState({
      race: state => state.race,
      competitors: state => state.competitors,
      auth: state => state.auth,
    }),
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.competitors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.competitors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.dispatch('deleteCompetitor', this.editedItem)
      this.closeDelete()
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    save() {
      this.$store.dispatch('saveCompetitor', this.editedItem)
      this.close()
    },
    back() {
      this.$router.go(-1)
    },
    differenceTime(a, b) {
      let startTime = new Date(a);
      let endTime = new Date(b);
      let time = endTime.getTime() - startTime.getTime();
      console.log(time);
    },
    getColor(ranking) {
      if (ranking == 1) return '#ffe7a6'
      else if (ranking == 2) return '#e3e3e3'
      else if (ranking == 3) return '#d4aa81'
      else return 'transparent'
    },
  },
}
</script>

<style scoped>

</style>
