 <template>
   <div>

      <v-carousel class="rounded-xl"  hide-delimiters height="800"  :show-arrows-on-hover="true" cycle>
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      gradient="to top right, rgba(89,88,89,0.33), rgba(121,121,121,0.8) 100%"
    >
      <v-row class="fill-height" align="center" justify="center" >
        <div class="display-3 ma-5" >
            {{ item.title }}
        </div>
      </v-row>

    </v-carousel-item>

  </v-carousel>
   </div>

</template>

<script>
  export default {
    data () {
      return {
        items: [
          {
            src: require('../assets/photos/one.jpg'),
            title: 'Once more',
          },
          {
            src: require('../assets/photos/two.jpg'),
            title: 'You can test yourself',
          },
          {
            src: require('../assets/photos/three.jpg'),
            title: 'Riding at',
          },
          {
            src: require('../assets/photos/four.jpg'),
            title: 'The MTB Rookie Contest',
          },
          {
            src: require('../assets/photos/five.jpg'),
            title: 'The new 2021\'s Edition',
          },
          {
            src: require('../assets/Logo_Big.png'),
          },
        ],
      }
    },
  }
</script>

