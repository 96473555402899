<template>
  <v-card class="rounded-xl pa-5" light>
    <v-card-title class="titleRes main my-5">
      Arrivals of Race {{ race_id }}
    </v-card-title>
    <v-row>
      <v-col>
        <v-card class="rounded-xl pa-lg-5 mb-8 mb-sm-2 pa-xs-10  pa-sm-10 " color="#111111">
          <v-card-title class="white--text" size="200">
            The Competitor
          </v-card-title>
          <v-hover v-slot:default="{ hover }">
            <v-img
                id="arrivalPhoto"
                class="rounded-xl"
                contain
                src="https://img.redbull.com/images/c_crop,w_5568,h_3712,x_0,y_0,f_auto,q_auto/c_scale,w_1500/redbullcom/2018/07/10/4f0eb2e5-f6fa-4ca6-98ff-29a0701516ea/mtb-collection"
                max-width="1100px">
              <v-expand-transition>
                <div v-if="hover"
                     class="d-flex transition-fast-in-fast-out black darken-2 v-card--reveal display-3 white--text"
                     style="height: 100%;">
                  <div class="pa-5">
                    <p class="innerDetails">
                      Bid Number : {{ bidNumber }} <br>First Name : {{ firstName }} <br>Last Name : {{ lastName }}
                    </p>
                  </div>
                </div>
              </v-expand-transition>
            </v-img>
          </v-hover>
        </v-card>
      </v-col>
      <v-col>
        <v-card id="scrollCard" class="rounded-xl scroll pa-5" color="#111111">
          <v-card-title class="white--text titleRes">Other Suggestions</v-card-title>
          <v-card v-for="item in items" :key="item" class="rounded-xl mb-8 pb-2 pl-2" color="white">
            <v-card-title class="titleRes Inner">
              Bib: #111111 <br>
              Name: Giovanni Giorgio
            </v-card-title>
            <v-btn absolute bottom class="mr-n1 mt-2" color="green" dark elevation="20" fab large right>
              <v-icon class="white--text" style="font-size: 2rem">mdi-check</v-icon>
            </v-btn>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <v-btn color="white" fab dark large absolute top left @click="back()">
      <v-icon class="black--text" style="font-size: 2rem">mdi-arrow-left</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "Arrival",
  data: () => ({
        items: 5,
        bidNumber: "#1111",
        firstName: "Giorgio",
        lastName: "Giovanni",
        startTime: "15:00:00",
        finishTime: "15:45:00",
      }
  ),
  beforeMount() {
    this.race_id = this.$route.params.id
    this.$store.dispatch('loadRace', {race_id: this.race_id})
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
  },
}

</script>

<style scoped>

</style>