<template>
  <v-card class="rounded-xl" color="transparent">
    <v-card class="rounded-xl pa-5" light>
      <v-card-title>
        Leaderboard corsa {{ race_id }}
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="competitors"
          :search="search"
          :sort-by="['ranking']"
      >
        <template v-slot:item.actions="{ item }" v-if="auth">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="ready(item)" :color="checkIcon.color">{{ checkIcon.name }}</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-card-actions class="pa-3">
      <v-dialog v-model="dialog" width="500" persistent v-if="auth">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" fab dark large top absolute right v-model="dialog" persistent v-bind="attrs" v-on="on"
                 elevation="4">
            <v-icon class="black--text" style="font-size: 2rem">mdi-account-plus-outline</v-icon>
          </v-btn>
        </template>
        <v-card class="rounded-xl pb-3">
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field v-model="editedItem.number" label="Bib number"></v-text-field>
              <v-text-field v-model="editedItem.surname" label="Surname"></v-text-field>
              <v-text-field v-model="editedItem.name" label="Name"></v-text-field>
              <v-text-field v-model="editedItem.birthdate" label="Birth date" type="date"></v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="black darken-1" text @click="close()">
              Close
            </v-btn>
            <v-btn color="black darken-1" text @click="save()">
              {{ dialogButton }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
    <v-btn color="white" fab dark large absolute top left @click="back()">
      <v-icon class="black--text" style="font-size: 2rem">mdi-arrow-left</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
// imports
import {mapState} from 'vuex'

export default {
  name: "Start",
  data: () => ({
    race_id: null,
    search: '',
    headers: [
      {text: 'Bib No.', value: 'number'},
      {text: 'Surname', value: 'surname'},
      {text: 'Name', value: 'name'},
      {text: 'BirthDate', value: 'birthdate'},
      {text: '', value: 'actions', sortable: false},
    ],
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    checked: false,
    checkIcon: {
      name: 'mdi-check-underline',
      color: 'black',
    },
    defaultItem: {
      number: '',
      surname: '',
      name: '',
      birthdate: '',
    },
    editedItem: {
      number: '',
      surname: '',
      name: '',
      birthdate: '',
    },
  }),
  beforeMount() {
    this.race_id = this.$route.params.id
    this.$store.dispatch('loadRace', {race_id: this.race_id})
  },
  computed: {
    dialogTitle() {
      return this.editedIndex === -1 ? 'Create competitor' : 'Edit competitor'
    },
    dialogButton() {
      return this.editedIndex === -1 ? 'Create' : 'Edit'
    },
    ...mapState({
      race: state => state.race,
      competitors: state => state.competitors,
      auth: state => state.auth,
    }),
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.competitors.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    save() {
      this.$store.dispatch('saveCompetitor', this.editedItem)
      this.close()
    },
    ready() {
      if (!this.checked) {
        this.$store.dispatch('ready', this.competitors.id)
        this.checkIcon.color = 'blue'
        this.checked = true
      }
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>

</style>