import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import About from './views/About.vue'
import Events from './views/Events'
import Races from './views/Races'
import Race from "./views/Race";
import Arrival from "./views/Arrival";
import Start from "./views/Start";
import Status from "./views/Status";
import Competitors from "./views/Competitors";

Vue.use(VueRouter)

const routes = [
   {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
         allowAnonymous: true
      }
   },
   {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
         allowAnonymous: true
      }
   },
   {
      path: '/events',
      name: 'Events',
      component: Events,
      meta: {
         allowAnonymous: true
      }
   },
   {
      path: '/races/:id',
      name: 'Races',
      component: Races,
      meta: {
         allowAnonymous: true
      }
   },
   {
      path: '/race/:id',
      name: 'Race',
      component: Race,
      meta: {
         allowAnonymous: true
      }
   },
   {
      path: '/arrival/:id',
      name: 'Arrival',
      component: Arrival,
      meta: {
         allowAnonymous: false,
      }
   },
   {
      path: '/start/:id',
      name: 'Start',
      component: Start,
      meta: {
         allowAnonymous: false,
      }
   },
   {
      path: '/status',
      name: 'Status',
      component: Status,
      meta: {
         allowAnonymous: false,
      }
   },
   {
      path: '/Competitors',
      name: 'Competitors',
      component: Competitors,
      meta: {
         allowAnonymous: false,
      }
   },
]

const router = new VueRouter({
   routes,
   mode: 'history'
})

export default router
